<template lang="pug">
  div
    v-row
      v-col(cols="3")
        v-toolbar(
          color="primary"
          dense
          elevation="0"
          dark
        )
          span Sow Pig Preferences
        edit-sow-pig-preferences
      v-col(cols="9")
        v-toolbar(
          color="primary"
          dense
          elevation="0"
          dark
        )
          span List
        table-sow-pig
</template>
<script>

export default {
  name: 'SowPig',
  components: {

    tableSowPig: () => import('./Table'),
    editSowPigPreferences: () => import('./preferences/Edit'),
  },
  data: () => ({

  }),
  computed: {
  },
}
</script>